import {styled} from '@mui/material';
import {memo} from 'react';

const Section = styled('div')(({ theme }) => {
    let paddings = { mobile: '48px 24px', desktop: '92px 64px' };
    return {
        display: 'flex',
        flexFlow: 'column nowrap',
        margin: 'auto',
        padding: paddings.desktop,
        width: '100%',
        maxWidth: 1920,
        [theme.breakpoints.down('md')]: {
            padding: paddings.mobile,
        },
    };
});

export default memo(Section);
